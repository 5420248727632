import { initializeApp } from "@firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "@firebase/app-check";
import { connectAuthEmulator, getAuth, onAuthStateChanged } from "@firebase/auth";
import firebaseConfig from "firebaseConfig";
import useOpenClose from "hooks/useOpenClose";
import { isNull, isUndefined } from "lodash";
import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

export const firebaseApp = initializeApp(firebaseConfig);

if (process.env.REACT_APP_ENVIRONMENT === "production" || process.env.REACT_APP_ENVIRONMENT === "staging") {
  initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaV3Provider(process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY),
    isTokenAutoRefreshEnabled: true,
  });
}

export const AuthContext = createContext();

export const AuthContextProvider = (props) => {
  const dispatch = useDispatch();

  const [user, setUser] = useState();
  const [error, setError] = useState(null);

  const [loading, startLoading, finishLoading] = useOpenClose(true);

  const isTesting = useMemo(() => !isUndefined(window.Cypress), []);

  const userHandler = useCallback(
    (userInfo) => {
      setUser(userInfo);
      finishLoading();

      if (!userInfo) dispatch({ type: "resetStore" });
    },
    [finishLoading, dispatch]
  );

  /* For tests environment */
  useEffect(() => {
    if (!isTesting) return null;

    startLoading();
    userHandler(JSON.parse(localStorage.getItem("authUser")));

    const onStorageChange = ({ key, newValue }) => {
      if (key !== "authUser") return;
      if (isNull(newValue)) userHandler(null);
      else userHandler(JSON.parse(newValue));
    };

    window.addEventListener("storage", onStorageChange);

    return () => window.removeEventListener("storage", onStorageChange);
  }, [isTesting, startLoading, finishLoading, userHandler]);

  useEffect(() => {
    if (isTesting) return () => {};

    startLoading();

    const auth = getAuth();
    if (process.env.REACT_APP_ENVIRONMENT !== "production" && process.env.REACT_APP_ENVIRONMENT !== "staging")
      connectAuthEmulator(auth, "http://localhost:9099");

    const unsubscribe = onAuthStateChanged(auth, userHandler, setError, () => finishLoading());
    return () => unsubscribe();
  }, [isTesting, userHandler, setError, startLoading, finishLoading]);

  return <AuthContext.Provider value={{ user, error, loading }} {...props} />;
};
