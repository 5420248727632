export const DEFAULT_SEO_DATA = {
  name: "Pedivan Ltd",
  fullname: "Pedivan Sustainable Delivery",
  title: "Zero Emission Delivery | Pedivan Sustainable Delivery",
  description:
    "Pedivan zero emission delivery, distribution, collection service. We only use purpuse built cargo trikes with an amazing capacity of 300kgs! Our bikes are eligible for use in all bus and cycle lanes, making us faster than any car! Insured, safe, closed cargo box fitted with alarm and immobilizer.",
  author: "pedivan",
};

export const MAX_DROP_AMOUNT = 24;
export const MAX_PACKAGE_AMOUNT = 5;

export const NEW_ORDER_FORM_STEPS = {
  SET_DELIVERY: "SET_DELIVERY",
  CALCULATE: "CALCULATE",
  BOOKING: "BOOKING",
  FINAL: "FINAL",
};

export const WORKING_HOURS_CONFIG = { start: 8, end: 22 };
export const PEAK_HOURS_CONFIG = { start: 11, end: 13 };
export const DELIVERY_TIME_GAP = 135;
export const DELIVERY_TIME_GAP_EXPRESS = 45;

export const SMALL_PACKAGE_TYPE = "small";
export const MEDIUM_PACKAGE_TYPE = "medium";
export const LARGE_PACKAGE_TYPE = "large";
export const PACKAGE_TYPES = {
  [SMALL_PACKAGE_TYPE]: "25x35x16",
  [MEDIUM_PACKAGE_TYPE]: "25x35x25",
  [LARGE_PACKAGE_TYPE]: "45x45x65",
};

export const QUANTITY_OPTIONS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const DEFAULT_USER_DETAILS = { firstName: "", lastName: "", email: "", phone: "" };

export const MAP_LIBRARIES = ["places"];
export const CUSTOM_MAP_STYLES = [
  { elementType: "geometry", stylers: [{ saturation: 10 }, { lightness: 25 }] },
  { featureType: "landscape", stylers: [{ saturation: -15 }] },
];

export const PENDING_STATUS = "pending";
export const REJECTED_STATUS = "rejected";
export const CANCELED_STATUS = "canceled";
export const ACCEPTED_STATUS = "accepted";
export const CLAIMED_STATUS = "claimed";
export const DELIVERED_STATUS = "delivered";
export const FAILED_STATUS = "failed";
export const RETURNED_STATUS = "returned";
export const ORDER_STATUSES_LIST = [
  PENDING_STATUS,
  REJECTED_STATUS,
  CANCELED_STATUS,
  ACCEPTED_STATUS,
  CLAIMED_STATUS,
  DELIVERED_STATUS,
  FAILED_STATUS,
  RETURNED_STATUS,
];

export const DEFAULT_LOCATION_DATA = {
  postcode: "",
  geolocation: [],
  location: "",
  city: "",
  country: "",
  secondLineAddress: "",
  buildingOrFlatNumber: "",
  recipient: { name: "", phone: "", email: "" },
  notes: "",
};

export const LIBRARY_STORAGE_LIMIT = 50;

export const DEFAULT_LOCALE = "en";
export const LOCALES_ENUM = { de: "Deutsch", en: "English", fr: "Français" };

export const DEFAULT_CITY = "London";
export const CITIES_LIST = ["London", "Berlin", "Paris"];

export const DEFAULT_COUNTRY = "gb";
export const COUNTRY_SHORTNAMES_BY_LOCALE = { en: "gb", de: "de", fr: "fr" };
export const COUNTRY_SHORTNAMES_BY_CITY = { London: "gb", Berlin: "de", Paris: "fr" };

export const INVOICE_PERIODS = { weekly: 1, biweekly: 2, monthly: 1 };
export const DEFAULT_INVOICE_SETTINGS = { period: "weekly", frequency: INVOICE_PERIODS.weekly };

export const INACTIVE_STATUS = "inactive";
export const EMAILED_STATUS = "emailed";
export const PAID_STATUS = "paid";
export const OVERDUE_STATUS = "overdue";
export const DELETED_STATUS = "deleted";
export const INVOICE_STATUSES_LIST = ["inactive", "emailed", "paid", "overdue", "deleted"];

export const REPEAT_ORDER_TYPES = { NONE: "NONE", EXACT_DATES: "EXACT_DATES" };
export const DEFAULT_REPEAT_ORDER_SETTINGS = { type: REPEAT_ORDER_TYPES.NONE };

export const LIBRARY_ITEM_TYPES = { addresses: "addresses", orders: "orders" };

export const TOAST_TYPES = { SUCCESS: "success", WARNING: "warning", ERROR: "error" };
